import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border, href }) {
  const ButtonComponent = href ? StyledLink : StyledButton;
  return (
    <ButtonComponent
      className="animate pointer radius8"
      onClick={action ? () => action() : undefined}
      border={border}
      href={href}
    >
      {title}
    </ButtonComponent>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#0b0935" : "#0b0935")}; /* Adjust border color */
  background-color: ${(props) =>
    props.border ? "transparent" : "#0b0935"}; /* Set background color */
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff; /* Set text color to white for contrast */
  :hover {
    background-color: ${(props) =>
      props.border
        ? "transparent"
        : "#0b0935"}; /* Keep background color consistent on hover */
    border: 1px solid ${(props) => (props.border ? "#0b0935" : "#0b0935")}; /* Keep border color consistent on hover */
    color: #fff; /* Keep text color consistent on hover */
  }
`;

const StyledButton = styled.button`
  border: 1px solid ${(props) => (props.border ? "#0b0935" : "#0b0935")};
  background-color: ${(props) => (props.border ? "transparent" : "#0b0935")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#0b0935")};
    border: 1px solid ${(props) => (props.border ? "#0b0935" : "#0b0935")};
    color: #fff;
  }
`;

const StyledLink = styled.a`
  display: inline-block; // Ensures it flows like a button
  text-align: center;
  border: 1px solid ${(props) => (props.border ? "#0b0935" : "#0b0935")};
  background-color: ${(props) => (props.border ? "transparent" : "#0b0935")};
  width: 100%;
  padding: 15px;
  color: #fff; // Ensures text is white
  text-decoration: none; // Removes underline from links
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#0b0935")};
    border: 1px solid ${(props) => (props.border ? "#0b0935" : "#0b0935")};
    color: #fff;
  }
`;
