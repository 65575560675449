import * as React from "react";

function SvgComponent({ width = "100px", height = "100px" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="ecd00e84ea">
          <path
            d="M 110 35.152344 L 119 35.152344 L 119 83 L 110 83 Z M 110 35.152344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="feef3256ec">
          <path
            d="M 146 35.152344 L 155 35.152344 L 155 83 L 146 83 Z M 146 35.152344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="e9326cb549">
          <path
            d="M 183 35.152344 L 192 35.152344 L 192 83 L 183 83 Z M 183 35.152344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="8722eb5bbd">
          <path
            d="M 220 35.152344 L 229 35.152344 L 229 83 L 220 83 Z M 220 35.152344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c714155962">
          <path
            d="M 256 35.152344 L 266 35.152344 L 266 83 L 256 83 Z M 256 35.152344 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="43f8d93506">
          <path
            d="M 110 291 L 119 291 L 119 339.652344 L 110 339.652344 Z M 110 291 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f0a658cef4">
          <path
            d="M 146 291 L 155 291 L 155 339.652344 L 146 339.652344 Z M 146 291 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="51c2dacdbb">
          <path
            d="M 183 291 L 192 291 L 192 339.652344 L 183 339.652344 Z M 183 291 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c002554d17">
          <path
            d="M 220 291 L 229 291 L 229 339.652344 L 220 339.652344 Z M 220 291 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="f230cbe0cc">
          <path
            d="M 256 291 L 266 291 L 266 339.652344 L 256 339.652344 Z M 256 291 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="4c5d465ab4">
          <path
            d="M 290 110 L 338.367188 110 L 338.367188 119 L 290 119 Z M 290 110 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="1ec6efa43a">
          <path
            d="M 290 146 L 338.367188 146 L 338.367188 155 L 290 155 Z M 290 146 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="aaf3b7b8f9">
          <path
            d="M 290 183 L 338.367188 183 L 338.367188 192 L 290 192 Z M 290 183 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c8f338bad9">
          <path
            d="M 290 219 L 338.367188 219 L 338.367188 228 L 290 228 Z M 290 219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="dc4848138f">
          <path
            d="M 290 256 L 338.367188 256 L 338.367188 265 L 290 265 Z M 290 256 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ba1c092b23">
          <path
            d="M 36.867188 110 L 85 110 L 85 119 L 36.867188 119 Z M 36.867188 110 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="c61a562972">
          <path
            d="M 36.867188 146 L 85 146 L 85 155 L 36.867188 155 Z M 36.867188 146 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="dc7b8e74a3">
          <path
            d="M 36.867188 183 L 85 183 L 85 192 L 36.867188 192 Z M 36.867188 183 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="baf529cb0d">
          <path
            d="M 36.867188 219 L 85 219 L 85 228 L 36.867188 228 Z M 36.867188 219 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="dd44791804">
          <path
            d="M 36.867188 256 L 85 256 L 85 265 L 36.867188 265 Z M 36.867188 256 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#ecd00e84ea)">
        <path
          fill="#ffbe00"
          d="M 114.363281 82.730469 C 111.980469 82.730469 110.195312 80.945312 110.195312 78.566406 L 110.195312 39.316406 C 110.195312 36.9375 111.980469 35.152344 114.363281 35.152344 C 116.746094 35.152344 118.53125 36.9375 118.53125 39.316406 L 118.53125 78.566406 C 118.53125 80.945312 116.746094 82.730469 114.363281 82.730469 Z M 114.363281 82.730469 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#feef3256ec)">
        <path
          fill="#ffbe00"
          d="M 150.691406 82.730469 C 148.308594 82.730469 146.523438 80.945312 146.523438 78.566406 L 146.523438 39.316406 C 146.523438 36.9375 148.308594 35.152344 150.691406 35.152344 C 153.074219 35.152344 154.859375 36.9375 154.859375 39.316406 L 154.859375 78.566406 C 154.859375 80.945312 153.074219 82.730469 150.691406 82.730469 Z M 150.691406 82.730469 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#e9326cb549)">
        <path
          fill="#ffbe00"
          d="M 187.617188 82.730469 C 185.234375 82.730469 183.449219 80.945312 183.449219 78.566406 L 183.449219 39.316406 C 183.449219 36.9375 185.234375 35.152344 187.617188 35.152344 C 190 35.152344 191.785156 36.9375 191.785156 39.316406 L 191.785156 78.566406 C 191.785156 80.945312 190 82.730469 187.617188 82.730469 Z M 187.617188 82.730469 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#8722eb5bbd)">
        <path
          fill="#ffbe00"
          d="M 224.539062 82.730469 C 222.160156 82.730469 220.371094 80.945312 220.371094 78.566406 L 220.371094 39.316406 C 220.371094 36.9375 222.160156 35.152344 224.539062 35.152344 C 226.921875 35.152344 228.710938 36.9375 228.710938 39.316406 L 228.710938 78.566406 C 228.710938 80.945312 226.921875 82.730469 224.539062 82.730469 Z M 224.539062 82.730469 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#c714155962)">
        <path
          fill="#ffbe00"
          d="M 260.871094 82.730469 C 258.488281 82.730469 256.699219 80.945312 256.699219 78.566406 L 256.699219 39.316406 C 256.699219 36.9375 258.488281 35.152344 260.871094 35.152344 C 263.253906 35.152344 265.039062 36.9375 265.039062 39.316406 L 265.039062 78.566406 C 265.039062 80.945312 263.253906 82.730469 260.871094 82.730469 Z M 260.871094 82.730469 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#43f8d93506)">
        <path
          fill="#ffbe00"
          d="M 114.363281 339.652344 C 111.980469 339.652344 110.195312 337.867188 110.195312 335.488281 L 110.195312 295.644531 C 110.195312 293.265625 111.980469 291.480469 114.363281 291.480469 C 116.746094 291.480469 118.53125 293.265625 118.53125 295.644531 L 118.53125 335.488281 C 118.53125 337.867188 116.746094 339.652344 114.363281 339.652344 Z M 114.363281 339.652344 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#f0a658cef4)">
        <path
          fill="#ffbe00"
          d="M 150.691406 339.652344 C 148.308594 339.652344 146.523438 337.867188 146.523438 335.488281 L 146.523438 295.644531 C 146.523438 293.265625 148.308594 291.480469 150.691406 291.480469 C 153.074219 291.480469 154.859375 293.265625 154.859375 295.644531 L 154.859375 335.488281 C 154.859375 337.867188 153.074219 339.652344 150.691406 339.652344 Z M 150.691406 339.652344 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#51c2dacdbb)">
        <path
          fill="#ffbe00"
          d="M 187.617188 339.652344 C 185.234375 339.652344 183.449219 337.867188 183.449219 335.488281 L 183.449219 295.644531 C 183.449219 293.265625 185.234375 291.480469 187.617188 291.480469 C 190 291.480469 191.785156 293.265625 191.785156 295.644531 L 191.785156 335.488281 C 191.785156 337.867188 190 339.652344 187.617188 339.652344 Z M 187.617188 339.652344 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#c002554d17)">
        <path
          fill="#ffbe00"
          d="M 224.539062 339.652344 C 222.160156 339.652344 220.371094 337.867188 220.371094 335.488281 L 220.371094 295.644531 C 220.371094 293.265625 222.160156 291.480469 224.539062 291.480469 C 226.921875 291.480469 228.710938 293.265625 228.710938 295.644531 L 228.710938 335.488281 C 228.710938 337.867188 226.921875 339.652344 224.539062 339.652344 Z M 224.539062 339.652344 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#f230cbe0cc)">
        <path
          fill="#ffbe00"
          d="M 260.871094 339.652344 C 258.488281 339.652344 256.699219 337.867188 256.699219 335.488281 L 256.699219 295.644531 C 256.699219 293.265625 258.488281 291.480469 260.871094 291.480469 C 263.253906 291.480469 265.039062 293.265625 265.039062 295.644531 L 265.039062 335.488281 C 265.039062 337.867188 263.253906 339.652344 260.871094 339.652344 Z M 260.871094 339.652344 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#4c5d465ab4)">
        <path
          fill="#ffbe00"
          d="M 334.125 118.414062 L 294.816406 118.414062 C 292.433594 118.414062 290.648438 116.628906 290.648438 114.25 C 290.648438 111.871094 292.433594 110.089844 294.816406 110.089844 L 334.125 110.089844 C 336.503906 110.089844 338.292969 111.871094 338.292969 114.25 C 338.292969 116.628906 336.503906 118.414062 334.125 118.414062 Z M 334.125 118.414062 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#1ec6efa43a)">
        <path
          fill="#ffbe00"
          d="M 334.125 154.691406 L 294.816406 154.691406 C 292.433594 154.691406 290.648438 152.910156 290.648438 150.53125 C 290.648438 148.152344 292.433594 146.367188 294.816406 146.367188 L 334.125 146.367188 C 336.503906 146.367188 338.292969 148.152344 338.292969 150.53125 C 338.292969 152.910156 336.503906 154.691406 334.125 154.691406 Z M 334.125 154.691406 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#aaf3b7b8f9)">
        <path
          fill="#ffbe00"
          d="M 334.125 191.566406 L 294.816406 191.566406 C 292.433594 191.566406 290.648438 189.78125 290.648438 187.402344 C 290.648438 185.023438 292.433594 183.238281 294.816406 183.238281 L 334.125 183.238281 C 336.503906 183.238281 338.292969 185.023438 338.292969 187.402344 C 338.292969 189.78125 336.503906 191.566406 334.125 191.566406 Z M 334.125 191.566406 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#c8f338bad9)">
        <path
          fill="#ffbe00"
          d="M 334.125 227.84375 L 294.816406 227.84375 C 292.433594 227.84375 290.648438 226.058594 290.648438 223.679688 C 290.648438 221.300781 292.433594 219.519531 294.816406 219.519531 L 334.125 219.519531 C 336.503906 219.519531 338.292969 221.300781 338.292969 223.679688 C 338.292969 226.058594 336.503906 227.84375 334.125 227.84375 Z M 334.125 227.84375 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#dc4848138f)">
        <path
          fill="#ffbe00"
          d="M 334.125 264.71875 L 294.816406 264.71875 C 292.433594 264.71875 290.648438 262.933594 290.648438 260.554688 C 290.648438 258.175781 292.433594 256.390625 294.816406 256.390625 L 334.125 256.390625 C 336.503906 256.390625 338.292969 258.175781 338.292969 260.554688 C 338.292969 262.933594 336.503906 264.71875 334.125 264.71875 Z M 334.125 264.71875 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#ba1c092b23)">
        <path
          fill="#ffbe00"
          d="M 80.417969 118.414062 L 41.109375 118.414062 C 38.726562 118.414062 36.941406 116.628906 36.941406 114.25 C 36.941406 111.871094 38.726562 110.089844 41.109375 110.089844 L 80.417969 110.089844 C 82.796875 110.089844 84.585938 111.871094 84.585938 114.25 C 84.585938 116.628906 82.796875 118.414062 80.417969 118.414062 Z M 80.417969 118.414062 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#c61a562972)">
        <path
          fill="#ffbe00"
          d="M 80.417969 154.691406 L 41.109375 154.691406 C 38.726562 154.691406 36.941406 152.910156 36.941406 150.53125 C 36.941406 148.152344 38.726562 146.367188 41.109375 146.367188 L 80.417969 146.367188 C 82.796875 146.367188 84.585938 148.152344 84.585938 150.53125 C 84.585938 152.910156 82.796875 154.691406 80.417969 154.691406 Z M 80.417969 154.691406 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#dc7b8e74a3)">
        <path
          fill="#ffbe00"
          d="M 80.417969 191.566406 L 41.109375 191.566406 C 38.726562 191.566406 36.941406 189.78125 36.941406 187.402344 C 36.941406 185.023438 38.726562 183.238281 41.109375 183.238281 L 80.417969 183.238281 C 82.796875 183.238281 84.585938 185.023438 84.585938 187.402344 C 84.585938 189.78125 82.796875 191.566406 80.417969 191.566406 Z M 80.417969 191.566406 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#baf529cb0d)">
        <path
          fill="#ffbe00"
          d="M 80.417969 227.84375 L 41.109375 227.84375 C 38.726562 227.84375 36.941406 226.058594 36.941406 223.679688 C 36.941406 221.300781 38.726562 219.519531 41.109375 219.519531 L 80.417969 219.519531 C 82.796875 219.519531 84.585938 221.300781 84.585938 223.679688 C 84.585938 226.058594 82.796875 227.84375 80.417969 227.84375 Z M 80.417969 227.84375 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <g clip-path="url(#dd44791804)">
        <path
          fill="#ffbe00"
          d="M 80.417969 264.71875 L 41.109375 264.71875 C 38.726562 264.71875 36.941406 262.933594 36.941406 260.554688 C 36.941406 258.175781 38.726562 256.390625 41.109375 256.390625 L 80.417969 256.390625 C 82.796875 256.390625 84.585938 258.175781 84.585938 260.554688 C 84.585938 262.933594 82.796875 264.71875 80.417969 264.71875 Z M 80.417969 264.71875 "
          fill-opacity="1"
          fill-rule="evenodd"
        />
      </g>
      <path
        fill="#049494"
        d="M 277.546875 299.804688 L 97.6875 299.804688 C 85.777344 299.804688 76.246094 290.289062 76.246094 278.394531 L 76.246094 95.816406 C 76.246094 84.515625 86.371094 74.40625 97.6875 74.40625 L 277.546875 74.40625 C 288.859375 74.40625 298.984375 84.515625 298.984375 95.816406 L 298.984375 278.394531 C 298.984375 290.289062 289.457031 299.804688 277.546875 299.804688 Z M 277.546875 299.804688 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#48b2b2"
        d="M 114.957031 103.546875 C 109.003906 103.546875 104.835938 107.710938 104.835938 113.0625 L 104.835938 261.742188 C 104.835938 267.097656 109.003906 271.257812 114.957031 271.257812 L 260.273438 271.257812 C 266.230469 271.257812 270.398438 267.097656 270.398438 261.742188 L 270.398438 113.0625 C 270.398438 107.710938 266.230469 103.546875 260.273438 103.546875 Z M 114.957031 103.546875 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 161.410156 271.257812 L 169.75 271.257812 L 169.75 230.816406 L 178.085938 219.519531 C 179.875 217.734375 179.277344 215.355469 177.492188 213.570312 C 175.707031 212.382812 173.324219 212.976562 171.535156 214.761719 L 162.007812 227.25 C 161.410156 227.84375 161.410156 228.4375 161.410156 229.628906 Z M 161.410156 271.257812 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 270.398438 212.382812 L 270.398438 204.054688 L 239.429688 204.054688 L 232.878906 196.917969 C 231.6875 195.132812 228.710938 195.132812 226.921875 196.917969 C 225.136719 198.109375 225.136719 201.082031 226.921875 202.867188 L 234.664062 211.191406 C 235.261719 211.785156 236.453125 212.382812 237.644531 212.382812 Z M 270.398438 212.382812 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 104.835938 182.644531 L 104.835938 190.972656 L 132.230469 190.972656 C 133.421875 190.972656 134.613281 190.375 135.207031 189.78125 L 145.925781 179.078125 L 167.964844 179.078125 C 170.34375 179.078125 172.132812 177.292969 172.132812 174.914062 C 172.132812 172.535156 170.34375 170.75 167.964844 170.75 L 144.140625 170.75 C 142.949219 170.75 141.757812 171.34375 141.164062 171.941406 L 130.441406 182.644531 Z M 104.835938 182.644531 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 213.226562 103.546875 L 204.886719 103.546875 L 204.886719 133.878906 C 204.886719 136.257812 206.675781 138.039062 209.054688 138.039062 C 211.4375 138.039062 213.226562 136.257812 213.226562 133.878906 Z M 213.226562 103.546875 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 241.214844 103.546875 L 232.878906 103.546875 L 232.878906 149.339844 C 232.878906 151.71875 234.664062 153.503906 237.046875 153.503906 C 239.429688 153.503906 241.214844 151.71875 241.214844 149.339844 Z M 241.214844 103.546875 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 104.835938 155.289062 L 104.835938 163.613281 L 132.230469 163.613281 C 133.421875 163.613281 134.613281 163.019531 135.207031 162.425781 L 142.949219 154.691406 C 144.738281 152.910156 144.738281 150.53125 142.949219 148.746094 C 141.164062 146.960938 138.78125 146.960938 136.996094 148.746094 L 130.441406 155.289062 Z M 104.835938 155.289062 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 270.398438 239.144531 L 270.398438 230.816406 L 222.753906 230.816406 L 215.609375 223.679688 C 213.820312 221.898438 211.4375 221.898438 209.652344 223.679688 C 207.867188 225.464844 207.867188 227.84375 209.652344 229.628906 L 217.988281 237.953125 C 218.585938 238.550781 219.777344 239.144531 220.96875 239.144531 Z M 270.398438 239.144531 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ffe879"
        d="M 135.207031 271.257812 L 143.546875 271.257812 L 143.546875 231.414062 C 143.546875 229.035156 141.757812 227.25 139.375 227.25 C 136.996094 227.25 135.207031 229.035156 135.207031 231.414062 Z M 135.207031 271.257812 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 237.046875 170.75 C 229.902344 170.75 223.945312 165.398438 223.945312 158.261719 C 223.945312 151.125 229.902344 145.175781 237.046875 145.175781 C 244.195312 145.175781 249.554688 151.125 249.554688 158.261719 C 249.554688 165.398438 244.195312 170.75 237.046875 170.75 Z M 237.046875 170.75 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 209.054688 155.289062 C 201.910156 155.289062 196.550781 149.933594 196.550781 142.796875 C 196.550781 135.066406 201.910156 129.714844 209.054688 129.714844 C 216.203125 129.714844 222.160156 135.660156 222.160156 142.796875 C 222.160156 149.933594 216.203125 155.289062 209.054688 155.289062 Z M 209.054688 155.289062 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 144.738281 157.667969 C 137.589844 157.667969 132.230469 152.3125 132.230469 145.175781 C 132.230469 138.039062 137.589844 132.09375 144.738281 132.09375 C 151.882812 132.09375 157.839844 138.039062 157.839844 145.175781 C 157.839844 152.3125 151.882812 157.667969 144.738281 157.667969 Z M 144.738281 157.667969 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 176.300781 187.996094 C 169.152344 187.996094 163.792969 182.050781 163.792969 174.914062 C 163.792969 167.777344 169.152344 162.425781 176.300781 162.425781 C 183.449219 162.425781 189.402344 167.777344 189.402344 174.914062 C 189.402344 182.050781 183.449219 187.996094 176.300781 187.996094 Z M 176.300781 187.996094 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 223.945312 207.027344 C 216.796875 207.027344 210.84375 201.082031 210.84375 193.945312 C 210.84375 186.808594 216.796875 181.457031 223.945312 181.457031 C 231.09375 181.457031 236.453125 186.808594 236.453125 193.945312 C 236.453125 201.082031 231.09375 207.027344 223.945312 207.027344 Z M 223.945312 207.027344 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 206.675781 233.195312 C 199.527344 233.195312 193.570312 227.84375 193.570312 220.707031 C 193.570312 213.570312 199.527344 207.625 206.675781 207.625 C 213.820312 207.625 219.179688 213.570312 219.179688 220.707031 C 219.179688 227.84375 213.820312 233.195312 206.675781 233.195312 Z M 206.675781 233.195312 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 180.46875 223.085938 C 173.324219 223.085938 167.367188 217.140625 167.367188 210.003906 C 167.367188 202.867188 173.324219 197.511719 180.46875 197.511719 C 187.617188 197.511719 192.976562 202.867188 192.976562 210.003906 C 192.976562 217.140625 187.617188 223.085938 180.46875 223.085938 Z M 180.46875 223.085938 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
      <path
        fill="#ff8b00"
        d="M 139.375 235.574219 C 132.230469 235.574219 126.871094 230.222656 126.871094 223.085938 C 126.871094 215.949219 132.230469 210.003906 139.375 210.003906 C 146.523438 210.003906 152.480469 215.949219 152.480469 223.085938 C 152.480469 230.222656 146.523438 235.574219 139.375 235.574219 Z M 139.375 235.574219 "
        fill-opacity="1"
        fill-rule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
