import * as React from "react";

function SvgComponent({ width = "100px", height = "100px" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="3928a920d5">
          <path
            d="M 37.5 156 L 337.5 156 L 337.5 318.75 L 37.5 318.75 Z M 37.5 156 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ab19b95571">
          <path
            d="M 37.5 106 L 337.5 106 L 337.5 269 L 37.5 269 Z M 37.5 106 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="71b4e2264f">
          <path
            d="M 37.5 56.25 L 337.5 56.25 L 337.5 219 L 37.5 219 Z M 37.5 56.25 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#3928a920d5)">
        <path
          fill="#b2deee"
          d="M 334.136719 231.960938 L 190.386719 156.960938 C 189.480469 156.488281 188.515625 156.253906 187.492188 156.253906 C 186.472656 156.253906 185.507812 156.488281 184.601562 156.960938 L 40.851562 231.960938 C 40.34375 232.226562 39.878906 232.554688 39.464844 232.945312 C 39.046875 233.335938 38.6875 233.773438 38.394531 234.265625 C 38.097656 234.753906 37.871094 235.273438 37.71875 235.824219 C 37.5625 236.375 37.484375 236.933594 37.484375 237.507812 C 37.484375 238.078125 37.5625 238.640625 37.71875 239.191406 C 37.871094 239.738281 38.097656 240.261719 38.394531 240.75 C 38.6875 241.238281 39.046875 241.675781 39.464844 242.066406 C 39.878906 242.457031 40.34375 242.785156 40.851562 243.050781 L 184.601562 318.050781 C 185.507812 318.523438 186.472656 318.761719 187.492188 318.761719 C 188.515625 318.761719 189.480469 318.523438 190.386719 318.050781 L 334.136719 243.050781 C 334.644531 242.785156 335.105469 242.457031 335.523438 242.066406 C 335.941406 241.675781 336.296875 241.238281 336.59375 240.75 C 336.890625 240.261719 337.117188 239.738281 337.269531 239.191406 C 337.425781 238.640625 337.5 238.078125 337.5 237.507812 C 337.5 236.933594 337.425781 236.375 337.269531 235.824219 C 337.117188 235.273438 336.890625 234.753906 336.59375 234.265625 C 336.296875 233.773438 335.941406 233.335938 335.523438 232.945312 C 335.105469 232.554688 334.644531 232.226562 334.136719 231.960938 Z M 334.136719 231.960938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#ab19b95571)">
        <path
          fill="#8794c5"
          d="M 334.136719 181.960938 L 190.386719 106.960938 C 189.480469 106.488281 188.515625 106.253906 187.492188 106.253906 C 186.472656 106.253906 185.507812 106.488281 184.601562 106.960938 L 40.851562 181.960938 C 40.34375 182.226562 39.878906 182.554688 39.464844 182.945312 C 39.046875 183.335938 38.6875 183.773438 38.394531 184.265625 C 38.097656 184.753906 37.871094 185.273438 37.71875 185.824219 C 37.5625 186.375 37.484375 186.933594 37.484375 187.507812 C 37.484375 188.078125 37.5625 188.640625 37.71875 189.191406 C 37.871094 189.738281 38.097656 190.261719 38.394531 190.75 C 38.6875 191.238281 39.046875 191.675781 39.464844 192.066406 C 39.878906 192.457031 40.34375 192.785156 40.851562 193.050781 L 184.601562 268.050781 C 185.507812 268.523438 186.472656 268.761719 187.492188 268.761719 C 188.515625 268.761719 189.480469 268.523438 190.386719 268.050781 L 334.136719 193.050781 C 334.644531 192.785156 335.105469 192.457031 335.523438 192.066406 C 335.941406 191.675781 336.296875 191.238281 336.59375 190.75 C 336.890625 190.261719 337.117188 189.738281 337.269531 189.191406 C 337.425781 188.640625 337.5 188.078125 337.5 187.507812 C 337.5 186.933594 337.425781 186.375 337.269531 185.824219 C 337.117188 185.273438 336.890625 184.753906 336.59375 184.265625 C 336.296875 183.773438 335.941406 183.335938 335.523438 182.945312 C 335.105469 182.554688 334.644531 182.226562 334.136719 181.960938 Z M 334.136719 181.960938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#71b4e2264f)">
        <path
          fill="#1976d2"
          d="M 334.136719 131.960938 L 190.386719 56.960938 C 189.480469 56.488281 188.515625 56.253906 187.492188 56.253906 C 186.472656 56.253906 185.507812 56.488281 184.601562 56.960938 L 40.851562 131.960938 C 40.34375 132.226562 39.878906 132.554688 39.464844 132.945312 C 39.046875 133.335938 38.6875 133.773438 38.394531 134.265625 C 38.097656 134.753906 37.871094 135.273438 37.71875 135.824219 C 37.5625 136.375 37.484375 136.933594 37.484375 137.507812 C 37.484375 138.078125 37.5625 138.640625 37.71875 139.191406 C 37.871094 139.738281 38.097656 140.261719 38.394531 140.75 C 38.6875 141.238281 39.046875 141.675781 39.464844 142.066406 C 39.878906 142.457031 40.34375 142.785156 40.851562 143.050781 L 184.601562 218.050781 C 185.507812 218.523438 186.472656 218.761719 187.492188 218.761719 C 188.515625 218.761719 189.480469 218.523438 190.386719 218.050781 L 334.136719 143.050781 C 334.644531 142.785156 335.105469 142.457031 335.523438 142.066406 C 335.941406 141.675781 336.296875 141.238281 336.59375 140.75 C 336.890625 140.261719 337.117188 139.738281 337.269531 139.191406 C 337.425781 138.640625 337.5 138.078125 337.5 137.507812 C 337.5 136.933594 337.425781 136.375 337.269531 135.824219 C 337.117188 135.273438 336.890625 134.753906 336.59375 134.265625 C 336.296875 133.773438 335.941406 133.335938 335.523438 132.945312 C 335.105469 132.554688 334.644531 132.226562 334.136719 131.960938 Z M 334.136719 131.960938 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
