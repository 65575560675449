import * as React from "react";

function SvgComponent({ width = "100px", height = "100px" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="d229037b77">
          <path
            d="M 84.824219 261.21875 L 322.863281 261.21875 L 322.863281 292.707031 L 84.824219 292.707031 Z M 84.824219 261.21875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="19d2d98f10">
          <path
            d="M 86 81.988281 L 204 81.988281 L 204 200 L 86 200 Z M 86 81.988281 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <path
        fill="#a4a6a9"
        d="M 306.902344 136.714844 L 306.902344 136.753906 L 100.179688 136.753906 L 100.179688 136.714844 C 100.179688 128.722656 106.460938 122.242188 114.203125 122.242188 L 292.875 122.242188 C 300.617188 122.242188 306.902344 128.722656 306.902344 136.714844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 103.363281 133.902344 L 303.71875 133.902344 C 302.507812 128.847656 298.101562 125.097656 292.875 125.097656 L 114.203125 125.097656 C 108.976562 125.097656 104.574219 128.847656 103.363281 133.902344 Z M 306.902344 139.605469 L 100.179688 139.605469 C 98.605469 139.605469 97.328125 138.328125 97.328125 136.753906 C 97.328125 127.164062 104.898438 119.390625 114.203125 119.390625 L 292.875 119.390625 C 302.179688 119.390625 309.753906 127.164062 309.753906 136.714844 L 309.753906 136.753906 C 309.753906 138.328125 308.476562 139.605469 306.902344 139.605469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#a4a6a9"
        d="M 230.503906 264.519531 L 230.503906 265.519531 C 230.503906 269.621094 227.644531 272.945312 224.121094 272.945312 L 187.183594 272.945312 C 183.65625 272.945312 180.792969 269.621094 180.792969 265.519531 L 180.792969 264.519531 L 87.824219 264.519531 L 87.824219 275.382812 C 87.824219 283.375 94.777344 289.859375 103.351562 289.859375 L 304.484375 289.859375 C 313.058594 289.859375 320.011719 283.375 320.011719 275.382812 L 320.011719 264.519531 L 230.503906 264.519531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#d229037b77)">
        <path
          fill="#100f0d"
          d="M 90.679688 267.371094 L 90.679688 275.378906 C 90.679688 281.789062 96.363281 287.003906 103.351562 287.003906 L 304.484375 287.003906 C 311.472656 287.003906 317.160156 281.789062 317.160156 275.378906 L 317.160156 267.371094 L 233.207031 267.371094 C 232.421875 272.15625 228.640625 275.796875 224.121094 275.796875 L 187.183594 275.796875 C 182.65625 275.796875 178.878906 272.15625 178.089844 267.371094 Z M 304.484375 292.707031 L 103.351562 292.707031 C 93.21875 292.707031 84.972656 284.9375 84.972656 275.382812 L 84.972656 264.519531 C 84.972656 262.941406 86.25 261.664062 87.824219 261.664062 L 180.792969 261.664062 C 182.371094 261.664062 183.644531 262.941406 183.644531 264.519531 L 183.644531 265.519531 C 183.644531 268 185.265625 270.089844 187.183594 270.089844 L 224.121094 270.089844 C 226.035156 270.089844 227.652344 268 227.652344 265.519531 L 227.652344 264.519531 C 227.652344 262.941406 228.929688 261.664062 230.503906 261.664062 L 320.011719 261.664062 C 321.589844 261.664062 322.867188 262.941406 322.867188 264.519531 L 322.867188 275.382812 C 322.867188 284.9375 314.621094 292.707031 304.484375 292.707031 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#69c4e8"
        d="M 100.179688 136.757812 L 100.179688 264.519531 L 175.71875 264.519531 L 175.71875 265.519531 C 175.71875 269.621094 178.773438 272.945312 182.539062 272.945312 L 221.96875 272.945312 C 225.730469 272.945312 228.785156 269.621094 228.785156 265.519531 L 228.785156 264.519531 L 306.902344 264.519531 L 306.902344 136.757812 L 100.179688 136.757812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 103.035156 261.664062 L 175.71875 261.664062 C 177.292969 261.664062 178.570312 262.941406 178.570312 264.519531 L 178.570312 265.519531 C 178.570312 268.039062 180.351562 270.089844 182.539062 270.089844 L 221.96875 270.089844 C 224.15625 270.089844 225.929688 268.039062 225.929688 265.519531 L 225.929688 264.519531 C 225.929688 262.941406 227.207031 261.664062 228.785156 261.664062 L 304.050781 261.664062 L 304.050781 139.609375 L 103.035156 139.609375 Z M 221.964844 275.796875 L 182.535156 275.796875 C 177.796875 275.796875 173.84375 272.15625 173.019531 267.371094 L 100.179688 267.371094 C 98.605469 267.371094 97.328125 266.09375 97.328125 264.519531 L 97.328125 136.757812 C 97.328125 135.179688 98.605469 133.902344 100.179688 133.902344 L 306.902344 133.902344 C 308.480469 133.902344 309.753906 135.179688 309.753906 136.757812 L 309.753906 264.519531 C 309.753906 266.09375 308.480469 267.371094 306.902344 267.371094 L 231.476562 267.371094 C 230.652344 272.15625 226.703125 275.796875 221.964844 275.796875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 283.945312 247.09375 L 289.453125 247.09375 L 289.453125 252.800781 L 283.945312 252.800781 L 283.945312 247.09375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 269.976562 247.09375 L 275.484375 247.09375 L 275.484375 252.800781 L 269.976562 252.800781 L 269.976562 247.09375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 255.007812 247.09375 L 260.515625 247.09375 L 260.515625 252.800781 L 255.007812 252.800781 L 255.007812 247.09375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 241.035156 247.09375 L 246.546875 247.09375 L 246.546875 252.800781 L 241.035156 252.800781 L 241.035156 247.09375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 154.308594 247.09375 L 230.976562 247.09375 L 230.976562 252.800781 L 154.308594 252.800781 L 154.308594 247.09375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#e93736"
        d="M 273.015625 167.398438 L 283.855469 167.398438 L 283.855469 222.34375 L 273.015625 222.34375 L 273.015625 167.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 275.871094 219.488281 L 281.003906 219.488281 L 281.003906 170.25 L 275.871094 170.25 Z M 283.855469 225.195312 L 273.019531 225.195312 C 271.441406 225.195312 270.164062 223.917969 270.164062 222.34375 L 270.164062 167.398438 C 270.164062 165.824219 271.441406 164.546875 273.019531 164.546875 L 283.855469 164.546875 C 285.433594 164.546875 286.710938 165.824219 286.710938 167.398438 L 286.710938 222.34375 C 286.710938 223.917969 285.433594 225.195312 283.855469 225.195312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#48a338"
        d="M 248.289062 174.234375 L 259.101562 174.234375 L 259.101562 222.34375 L 248.289062 222.34375 L 248.289062 174.234375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 251.144531 219.488281 L 256.25 219.488281 L 256.25 177.085938 L 251.144531 177.085938 Z M 259.101562 225.195312 L 248.289062 225.195312 C 246.714844 225.195312 245.4375 223.917969 245.4375 222.34375 L 245.4375 174.234375 C 245.4375 172.660156 246.714844 171.382812 248.289062 171.382812 L 259.101562 171.382812 C 260.679688 171.382812 261.957031 172.660156 261.957031 174.234375 L 261.957031 222.34375 C 261.957031 223.917969 260.679688 225.195312 259.101562 225.195312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#f7c72b"
        d="M 223.664062 180.832031 L 234.476562 180.832031 L 234.476562 222.34375 L 223.664062 222.34375 L 223.664062 180.832031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 226.519531 219.488281 L 231.625 219.488281 L 231.625 183.683594 L 226.519531 183.683594 Z M 234.476562 225.195312 L 223.664062 225.195312 C 222.089844 225.195312 220.8125 223.917969 220.8125 222.34375 L 220.8125 180.832031 C 220.8125 179.253906 222.089844 177.976562 223.664062 177.976562 L 234.476562 177.976562 C 236.050781 177.976562 237.328125 179.253906 237.328125 180.832031 L 237.328125 222.34375 C 237.328125 223.917969 236.050781 225.195312 234.476562 225.195312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#52aee5"
        d="M 199.035156 190.066406 L 209.851562 190.066406 L 209.851562 222.34375 L 199.035156 222.34375 L 199.035156 190.066406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 201.890625 219.488281 L 206.996094 219.488281 L 206.996094 192.921875 L 201.890625 192.921875 Z M 209.851562 225.195312 L 199.035156 225.195312 C 197.460938 225.195312 196.183594 223.917969 196.183594 222.34375 L 196.183594 190.066406 C 196.183594 188.492188 197.460938 187.214844 199.035156 187.214844 L 209.851562 187.214844 C 211.425781 187.214844 212.703125 188.492188 212.703125 190.066406 L 212.703125 222.34375 C 212.703125 223.917969 211.425781 225.195312 209.851562 225.195312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#c8e773"
        d="M 174.308594 199.683594 L 185.121094 199.683594 L 185.121094 222.34375 L 174.308594 222.34375 L 174.308594 199.683594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 177.160156 219.488281 L 182.269531 219.488281 L 182.269531 202.535156 L 177.160156 202.535156 Z M 185.121094 225.195312 L 174.308594 225.195312 C 172.730469 225.195312 171.457031 223.917969 171.457031 222.34375 L 171.457031 199.683594 C 171.457031 198.105469 172.730469 196.828125 174.308594 196.828125 L 185.121094 196.828125 C 186.699219 196.828125 187.976562 198.105469 187.976562 199.683594 L 187.976562 222.34375 C 187.976562 223.917969 186.699219 225.195312 185.121094 225.195312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 169.371094 231.914062 L 291.417969 231.914062 L 291.417969 237.617188 L 169.371094 237.617188 L 169.371094 231.914062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#b81c2c"
        d="M 56.945312 211.441406 C 55.863281 215.265625 56.9375 219.378906 59.75 222.191406 L 64.019531 226.460938 C 68.316406 230.75 75.273438 230.75 79.566406 226.457031 L 120.773438 185.226562 C 123.960938 182.035156 124.875 177.222656 123.082031 173.085938 C 105.421875 192.710938 82.164062 206.171875 56.945312 211.441406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#d41f31"
        d="M 56.945312 211.441406 C 82.164062 206.171875 105.421875 192.710938 123.082031 173.085938 C 122.53125 171.816406 121.746094 170.664062 120.765625 169.6875 L 116.496094 165.414062 C 112.203125 161.128906 105.246094 161.128906 100.953125 165.417969 L 59.746094 206.652344 C 58.417969 207.980469 57.449219 209.628906 56.945312 211.441406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 60.542969 209.886719 C 58.046875 212.394531 58.046875 216.449219 60.546875 218.953125 L 67.265625 225.664062 C 68.464844 226.871094 70.09375 227.546875 71.792969 227.539062 C 73.496094 227.542969 75.125 226.867188 76.324219 225.664062 L 119.972656 181.988281 C 122.476562 179.480469 122.472656 175.425781 119.972656 172.921875 L 113.257812 166.210938 C 112.058594 165.003906 110.425781 164.332031 108.726562 164.335938 C 107.027344 164.332031 105.394531 165.007812 104.195312 166.214844 Z M 71.792969 233.242188 C 68.582031 233.253906 65.5 231.976562 63.234375 229.699219 L 56.515625 222.988281 C 51.789062 218.257812 51.785156 210.59375 56.507812 205.855469 L 100.160156 162.183594 C 102.425781 159.902344 105.507812 158.625 108.722656 158.632812 L 108.726562 158.632812 C 111.941406 158.621094 115.023438 159.898438 117.289062 162.175781 L 124.007812 168.886719 C 128.730469 173.621094 128.734375 181.285156 124.011719 186.019531 L 80.363281 229.695312 C 78.097656 231.972656 75.011719 233.253906 71.796875 233.242188 L 71.792969 233.242188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#d41f31"
        d="M 89.394531 144.421875 C 91.472656 175.113281 118.039062 198.304688 148.726562 196.226562 C 179.417969 194.148438 202.609375 167.585938 200.53125 136.894531 C 198.453125 106.207031 171.890625 83.011719 141.199219 85.09375 C 141.199219 85.09375 141.195312 85.09375 141.195312 85.09375 C 110.507812 87.171875 87.316406 113.734375 89.394531 144.421875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#19d2d98f10)">
        <path
          fill="#100f0d"
          d="M 92.238281 144.230469 C 94.210938 173.347656 119.414062 195.355469 148.535156 193.382812 C 177.652344 191.414062 199.660156 166.210938 197.691406 137.09375 C 195.71875 107.972656 170.515625 85.96875 141.398438 87.9375 C 141.394531 87.9375 141.390625 87.941406 141.386719 87.941406 C 112.289062 89.949219 90.304688 115.128906 92.238281 144.230469 Z M 144.921875 199.203125 C 112.554688 199.195312 86.320312 172.953125 86.328125 140.585938 C 86.335938 108.21875 112.582031 81.980469 144.949219 81.988281 C 177.320312 81.996094 203.550781 108.242188 203.546875 140.609375 C 203.539062 171.425781 179.671875 196.96875 148.925781 199.070312 C 147.585938 199.15625 146.246094 199.203125 144.921875 199.203125 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#4155ad"
        d="M 144.785156 98.5 C 168.074219 98.5 186.957031 117.378906 186.957031 140.667969 C 186.957031 163.957031 168.074219 182.839844 144.785156 182.839844 C 121.496094 182.839844 102.617188 163.957031 102.617188 140.667969 C 102.617188 117.378906 121.496094 98.5 144.785156 98.5 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#334a94"
        d="M 105.601562 156.273438 C 114.21875 177.910156 138.746094 188.46875 160.386719 179.847656 C 182.023438 171.230469 192.578125 146.703125 183.960938 125.066406 C 182.1875 120.609375 179.667969 116.492188 176.507812 112.886719 C 185.128906 134.523438 174.574219 159.050781 152.933594 167.671875 C 137.289062 173.902344 119.433594 170.230469 107.519531 158.324219 C 106.847656 157.660156 106.210938 156.976562 105.601562 156.273438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 144.785156 101.351562 L 144.773438 101.351562 C 123.058594 101.355469 105.460938 118.964844 105.464844 140.679688 C 105.46875 162.386719 123.070312 179.984375 144.785156 179.984375 L 144.796875 179.984375 C 166.511719 179.976562 184.109375 162.371094 184.101562 140.65625 C 184.097656 118.949219 166.496094 101.351562 144.785156 101.351562 Z M 144.785156 185.691406 C 119.929688 185.683594 99.78125 165.535156 99.785156 140.675781 C 99.789062 115.820312 119.941406 95.675781 144.796875 95.679688 C 169.65625 95.683594 189.800781 115.835938 189.796875 140.691406 C 189.796875 165.542969 169.652344 185.683594 144.796875 185.691406 L 144.785156 185.691406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 114.820312 143.566406 C 113.246094 143.566406 111.964844 142.289062 111.964844 140.714844 C 111.964844 130.832031 116.941406 122.410156 121.871094 118.445312 C 123.089844 117.449219 124.886719 117.628906 125.882812 118.847656 C 126.882812 120.070312 126.699219 121.867188 125.480469 122.863281 C 125.46875 122.875 125.457031 122.882812 125.445312 122.890625 C 121.703125 125.902344 117.671875 132.765625 117.671875 140.714844 C 117.671875 142.289062 116.394531 143.566406 114.820312 143.566406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#100f0d"
        d="M 130.21875 116.546875 C 128.644531 116.546875 127.367188 115.273438 127.363281 113.699219 C 127.363281 112.867188 127.722656 112.082031 128.347656 111.539062 C 129.859375 110.226562 131.445312 109.007812 133.101562 107.882812 C 134.40625 107 136.179688 107.339844 137.0625 108.644531 C 137.949219 109.949219 137.605469 111.722656 136.300781 112.605469 C 134.832031 113.601562 133.425781 114.683594 132.085938 115.847656 C 131.570312 116.296875 130.90625 116.546875 130.21875 116.546875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
}

export default SvgComponent;
