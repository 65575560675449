import React from "react";
import styled from "styled-components";
// Components

// Assets
import AccuracyIcon from "../../assets/svg/accuracy.svg";
import ComplianceIcon from "../../assets/svg/compliance.svg";
import EfficiencyIcon from "../../assets/svg/efficiency.svg";
import LowcostIcon from "../../assets/svg/lowcost.svg";

export default function PricingTable({
  icon,
  price,
  title,
  text,
  offers,
  action,
}) {
  let getIcon;

  switch (icon) {
    case "efficiency":
      getIcon = <img src={EfficiencyIcon} alt="Efficiency Icon" />;
      break;
    case "accuracy":
      getIcon = <img src={AccuracyIcon} alt="Accuracy Icon" />;
      break;
    case "lowcost":
      getIcon = <img src={LowcostIcon} alt="Low Cost Icon" />;
      break;
    case "compliance":
      getIcon = <img src={ComplianceIcon} alt="Compliance Icon" />;
      break;
    default:
      getIcon = <img src={ComplianceIcon} alt="Default Icon" />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow">
      <div className="flexSpaceCenter">
        {getIcon}
        <p className="font30 extraBold">{price}</p>
      </div>
      <div style={{ margin: "10px 0" }}>
        <h4 className="font25 extraBold">{title}</h4>
        <p className="font14">{text}</p>
      </div>
      <div>
        {offers &&
          offers.map((item, index) => (
            <div
              className="flexNullCenter"
              style={{ margin: "15px 0" }}
              key={index}
            >
              <div
                style={{
                  position: "relative",
                  top: "-1px",
                  marginRight: "15px",
                }}
              >
                {item.checked ? (
                  <div style={{ minWidth: "20px" }}>
                    {/* Ensure CheckMark is correctly imported or handled */}
                  </div>
                ) : (
                  <div style={{ minWidth: "20px" }}></div>
                )}
              </div>
              <p className="font20 extraBold">{item.name}</p>
            </div>
          ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;
