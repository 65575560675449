import React from "react";
import styled from "styled-components";
import Logo1 from "../../assets/svg/ibm.svg";
import Logo2 from "../../assets/svg/hipaa.svg";
import Logo3 from "../../assets/svg/mongodb.svg";
import Logo4 from "../../assets/svg/google.svg";
import Logo5 from "../../assets/svg/openai.svg";

export default function ClientLogos() {
  return (
    <LogoContainer>
      <StyledImg src={Logo1} alt="IBM Logo" />
      <StyledImg src={Logo2} alt="HIPAA Logo" />
      <StyledImg src={Logo3} alt="OpenAI Logo" />
      <StyledImg src={Logo4} alt="Google Logo" />
      <StyledImg src={Logo5} alt="Mongodb" />
    </LogoContainer>
  );
}

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 10px 0;
`;

const StyledImg = styled.img`
  height: 80px; // Ensures all logos are the same height
  width: auto; // Maintains aspect ratio
  padding: 0 10px;
`;
