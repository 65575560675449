import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-3.jpg";

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <ContentArea>
            <LeftContent>
              <HeaderInfo>
                <h1
                  className="font30 extraBold"
                  style={{ marginBottom: "20px" }}
                >
                  Get in touch
                </h1>
                <p
                  className="font13"
                  style={{ lineHeight: "1.6", textAlign: "left" }}
                >
                  Ready to transform your business with cutting-edge technology?
                  At NeuClaims, we’re eager to explore how our solutions
                  can drive your success. Connect with us to discuss how a partnership could improve your operations.
                  Whether you have a question, a project, or need expert advice, our team is here to help.
                </p>
              </HeaderInfo>
              <ContactInfo>
                {/* <div>
                  <h4 className="font25 extraBold">Phone:</h4>
                  <p className="font15">+234 9123-456-7890</p>
                </div> */}
                <div>
                  <h4 className="font25 extraBold">Email:</h4>
                  <p className="font15">hello@neuclaims.com</p>
                </div>
                {/* <div>
                  <h4 className="font25 extraBold">Address:</h4>
                  <p className="font15">
                    123 Tech Avenue, Innovation Park, San Francisco, CA 94102
                  </p>
                </div> */}
              </ContactInfo>
            </LeftContent>
            <RightContent>
              <img
                src={ContactImg1}
                alt="Contact Us"
                className="contactImage"
              />
            </RightContent>
          </ContentArea>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 20px 0;
`;

const ContentArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
`;

const LeftContent = styled.div`
  flex: 1;
  margin-top: 25px;
  padding-right: 20px; // Adds some spacing between the text and the image
`;

const RightContent = styled.div`
  flex: 0 0 300px; // Fixes the width of the right content to 300px
  margin-top: 25px;
  margin-bottom: 25px;
  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 20px;
`;

const ContactInfo = styled.div`
  div {
    margin-bottom: 15px;
  }
`;
