import React from "react";
import styled from "styled-components";
// Assets
import Network from "../../assets/svg/Services/Network";
import Cpu from "../../assets/svg/Services/Cpu";
import Layers from "../../assets/svg/Services/Layers";
import Chart from "../../assets/svg/Services/Chart";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "network":
      getIcon = <Network width="100px" height="100px" />;
      break;
    case "cpu":
      getIcon = <Cpu width="100px" height="100px" />;
      break;
    case "layers":
      getIcon = <Layers width="100px" height="100px" />;
      break;
    case "chart":
      getIcon = <Chart width="100px" height="100px" />;
      break;
    default:
      getIcon = <Network width="100px" height="100px"/>;
      break;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns content to the left */
  width: 100%;
`;

const IconStyle = styled.div`
  display: flex;
  align-items: center; // Centers the icon vertically if needed
  justify-content: flex-start; // Aligns the icon to the left
  width: 100%; // Ensures the wrapper takes the full width of its container
`;
// const IconStyle = styled.div`
//   @media (max-width: 860px) {
//     margin: 0 auto;
//   }
// `;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
