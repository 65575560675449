import React from "react";
import styled from "styled-components";
// Components
//import BenefitBox from "../Elements/BenefitBox"; // Assume we have a BenefitBox component
import PricingTable from "../Elements/PricingTable";

export default function Benefits() {
  return (
    <Wrapper id="benefits">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Benefits</h1>
            <p className="font13">
              Discover the advantages of using NeuClaims for your health
              insurance claim processing.
            </p>
          </HeaderInfo>
          <BoxesWrapper className="flexSpaceNull">
            <Box>
              <PricingTable
                icon="efficiency"
                title="Increased Efficiency"
                text="Reduce the time it takes to process claims with automated data entry and decision-making, allowing your team to focus on more strategic tasks."
              />
            </Box>
            <Box>
              <PricingTable
                icon="accuracy"
                title="Enhanced Accuracy"
                text="Minimize human errors with AI-powered verification processes that ensure claims are accurate and compliant with policy rules."
              />
            </Box>
            <Box>
              <PricingTable
                icon="lowcost"
                title="Cost Reduction"
                text="Lower operational costs by reducing the need for manual processing and minimizing claim denials through improved accuracy."
              />
            </Box>
            <Box>
              <PricingTable
                icon="compliance"
                title="Regulatory Compliance"
                text="Ensure compliance with regional and international health insurance regulations through automated checks and balances."
              />
            </Box>
          </BoxesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const BoxesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const Box = styled.div`
  width: 23%; // Adjusted to fit four boxes horizontally
  margin-right: 2%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;
